// MyPlan.jsx
import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { useLocation } from "react-router-dom";
import { BsHouse } from "react-icons/bs";
import { useTheme } from "./ThemeContext";
import { useNavigate } from "react-router-dom";
import useFetchWithAuth from "./MainPageComponents/useFetchWithAuth.js";

function MyPlan() {
  const [activeTab, setActiveTab] = useState("Professional");
  const [notification, setNotification] = useState("");
  const [showCheckoutSummary, setShowCheckoutSummary] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [couponCode, setCouponCode] = useState("");
  const [couponError, setCouponError] = useState("");
  const [discount, setDiscount] = useState(0);

  const location = useLocation();
  const { theme } = useTheme();
  const navigate = useNavigate();
  const userEmail = location.state?.userEmail;
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const fetchWithAuth = useFetchWithAuth(serverUrl);

  const calculateStripeFee = (amount) => {
    return (amount * 0.029 + 0.3).toFixed(2);
  };

  const calculateTotal = (subtotal, discount) => {
    const discountedAmount = subtotal - discount;
    const stripeFee = parseFloat(calculateStripeFee(discountedAmount));
    const tax = parseFloat((discountedAmount * 0.0775).toFixed(2));
    return (discountedAmount + stripeFee + tax).toFixed(2);
  };

  // const validateCoupon = async () => {
  //   console.log("Starting coupon validation...");
  //   setCouponError("");

  //   if (!selectedPlan) {
  //     console.log("No plan selected");
  //     setCouponError("Please select a plan first");
  //     return false;
  //   }

  //   if (!couponCode) {
  //     console.log("No coupon code entered");
  //     setCouponError("Please enter a coupon code");
  //     return false;
  //   }

  //   console.log("Validating coupon with:", {
  //     couponCode,
  //     planType: selectedPlan.planName,
  //     selectedPlan,
  //   });

  //   try {
  //     const response = await fetchWithAuth(`${serverUrl}/api/validate-coupon`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         couponCode: couponCode.trim().toUpperCase(),
  //         planType: selectedPlan.planName,
  //       }),
  //     });

  //     console.log("Coupon validation response status:", response.status);
  //     const data = await response.json();
  //     console.log("Coupon validation response data:", data);

  //     if (response.ok && data.valid) {
  //       console.log("Coupon valid, applying discount:", data.discountAmount);
  //       setDiscount(data.discountAmount);
  //       setCouponError("");
  //       return true;
  //     } else {
  //       console.log("Coupon invalid:", data.message);
  //       setCouponError(data.message || "The entered coupon is not valid");
  //       setDiscount(0);
  //       return false;
  //     }
  //   } catch (error) {
  //     console.error("Error validating coupon:", error);
  //     setCouponError("Error validating coupon. Please try again.");
  //     setDiscount(0);
  //     return false;
  //   }
  // };

  const validateCoupon = async () => {
    console.log("Starting coupon validation...");
    setCouponError("");

    if (!selectedPlan) {
      console.log("No plan selected");
      setCouponError("Please select a plan first");
      return false;
    }

    if (!couponCode) {
      console.log("No coupon code entered");
      setCouponError("Please enter a coupon code");
      return false;
    }

    console.log("Validating coupon with:", {
      couponCode,
      planType: selectedPlan.planName,
      selectedPlan,
    });

    try {
      const response = await fetchWithAuth(`${serverUrl}/api/validate-coupon`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          couponCode: couponCode.trim().toUpperCase(),
          planType: selectedPlan.planName,
        }),
      });

      console.log("Coupon validation response status:", response.status);
      const data = await response.json();
      console.log("Coupon validation response data:", data);

      if (!response.ok) {
        setCouponError(data.message || "Failed to validate coupon");
        setDiscount(0);
        return false;
      }

      if (data.valid) {
        console.log("Coupon valid, applying discount:", data.discountAmount);
        setDiscount(data.discountAmount);
        // Recalculate total here if needed
        return true;
      } else {
        console.log("Coupon invalid:", data.message);
        setCouponError(data.message || "The entered coupon is not valid");
        setDiscount(0);
        return false;
      }
    } catch (error) {
      console.error("Error validating coupon:", error);
      setCouponError("Error validating coupon. Please try again.");
      setDiscount(0);
      return false;
    }
  };

  const makePayment = async (plan) => {
    if (plan.planName === "recharge") {
      setNotification("You need to first subscribe to a plan.");
      return;
    }

    // Clear any existing notifications
    setNotification("");
    setSelectedPlan(plan);
    setShowCheckoutSummary(true);
  };

  // Frontend (MyPlan.jsx)
  const handleProceedToStripe = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      if (!token) {
        setNotification("Please log in to continue");
        navigate("/login");
        return;
      }

      if (couponCode) {
        const isValidCoupon = await validateCoupon();
        if (!isValidCoupon) {
          return;
        }
      }

      const subtotal = selectedPlan.price;
      const total = calculateTotal(subtotal, discount);

      console.log("Checkout Details:", {
        selectedPlan,
        total,
        discount,
        userEmail,
        token: token ? "Present" : "Missing", // Log token presence
      });

      const body = {
        email: userEmail,
        products: [
          {
            ...selectedPlan,
            finalAmount: total,
            originalPrice: subtotal,
            appliedDiscount: discount,
            stripeFee: calculateStripeFee(subtotal - discount),
            tax: ((subtotal - discount) * 0.0775).toFixed(2),
          },
        ],
        couponCode: couponCode || null,
      };

      console.log("Request Body:", body);

      // Use fetchWithAuth instead of fetch
      const response = await fetchWithAuth(
        `${serverUrl}/api/create-checkout-session`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );

      console.log("Response Status:", response.status);

      // Handle non-JSON responses
      const responseText = await response.text();
      console.log("Raw Response:", responseText);

      if (!response.ok) {
        if (response.status === 403) {
          setNotification("Session expired. Please log in again.");
          navigate("/login");
          return;
        }
        throw new Error(responseText || "Failed to create checkout session");
      }

      let responseData;
      try {
        responseData = JSON.parse(responseText);
      } catch (e) {
        throw new Error("Invalid response from server");
      }

      if (!responseData.id) {
        throw new Error("No session ID returned from server");
      }

      const stripe = await loadStripe(process.env.REACT_APP_STRIPE_KEY);
      if (!stripe) {
        throw new Error("Failed to initialize Stripe");
      }

      const result = await stripe.redirectToCheckout({
        sessionId: responseData.id,
      });

      if (result.error) {
        throw new Error(`Stripe redirect error: ${result.error.message}`);
      }
    } catch (error) {
      console.error("Detailed checkout error:", {
        message: error.message,
        stack: error.stack,
        name: error.name,
      });
      setNotification(`Checkout failed: ${error.message}`);
    }
  };

  // const validateCoupon = async () => {
  //   setCouponError("");

  //   if (!selectedPlan) {
  //     setCouponError("Please select a plan first");
  //     return false;
  //   }

  //   try {
  //     const response = await fetchWithAuth(`${serverUrl}/api/validate-coupon`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         couponCode,
  //         planType: selectedPlan.planName,
  //       }),
  //     });

  //     const data = await response.json();
  //     console.log("Coupon validation response:", data);

  //     if (response.ok && data.valid) {
  //       setDiscount(data.discountAmount);
  //       return true;
  //     } else {
  //       setCouponError(data.message || "The entered coupon is not valid");
  //       return false;
  //     }
  //   } catch (error) {
  //     console.error("Error validating coupon:", error);
  //     setCouponError("Error validating coupon");
  //     return false;
  //   }
  // };

  const plans = {
    Recharge: [
      {
        name: "Recharge",
        planName: "recharge",
        price: 4.99,
        description: [
          // "✔️ Add $4 to your usage cap",
          // "✔️ Covers Stripe and AWS fees",
        ],
      },
    ],

    Basic: [
      {
        name: "Monthly",
        planName: "basic_monthly",
        price: 9.99,
        description: [
          "✔️ Use advanced Gen AI models, like Claude 3.5 Sonnet, GPT4o, Gemini 1.5 Pro, and others",
        ],
      },
      {
        name: "Quarterly",
        planName: "basic_quarterly",
        price: 26.99,
        usage: "2000 tokens",
        description: [
          "✔️ Use advanced Gen AI models, like Claude 3.5 Sonnet, GPT4o, Gemini 1.5 Pro, and others",
          "✔️ Enjoy higher monthly message limits",
        ],
      },
      {
        name: "Yearly",
        planName: "basic_yearly",
        price: 99.99,
        usage: "3000 tokens",
        description: [
          "✔️ Use advanced Gen AI models, like Claude 3.5 Sonnet, GPT4o, Gemini 1.5 Pro, and others",
          "✔️ Enjoy higher monthly message limits",
        ],
      },
    ],
    Professional: [
      {
        name: "Monthly",
        planName: "pro_monthly",
        price: 49.99,
        usage: "12000 tokens",
        description: [
          "✔️ Use advanced Gen AI models, like Claude 3.5 Sonnet, GPT4o, Gemini 1.5 Pro, and others",
          "✔️ Enjoy higher monthly message limits",
        ],
      }, // 2 months free
      {
        name: "Quarterly",
        planName: "pro_quarterly",
        price: 129.99,
        apiVersion: "GPT-4-turbo",
        usage: "24000 tokens",
        description: [
          "✔️ Use advanced Gen AI models, like Claude 3.5 Sonnet, GPT4o, Gemini 1.5 Pro, and others",
          "✔️ Enjoy higher monthly message limits",
        ],
      },
      {
        name: "Yearly",
        planName: "pro_yearly",
        price: 499.99,
        apiVersion: "Gemini",
        usage: "36000 tokens",
        description: [
          "✔️ Use advanced Gen AI models, like Claude 3.5 Sonnet, GPT4o, Gemini 1.5 Pro, and others",
          "✔️ Enjoy higher monthly message limits",
        ],
      },
    ],
  };

  const CheckoutSummary = () => {
    const subtotal = selectedPlan.price;
    const stripeFee = calculateStripeFee(subtotal - discount);
    const tax = ((subtotal - discount) * 0.0775).toFixed(2);
    const total = (
      parseFloat(subtotal) +
      parseFloat(stripeFee) +
      parseFloat(tax) -
      discount
    ).toFixed(2);

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl w-full max-w-md p-6">
          <h2 className="text-2xl font-bold text-center mb-6">Checkout</h2>
          <div className="space-y-4">
            <div className="space-y-2">
              <h3 className="font-medium">Plan Details</h3>
              <div className="flex justify-between">
                <span>{selectedPlan.name}</span>
                <span>${selectedPlan.price}</span>
              </div>
              {selectedPlan.description.map((desc, index) => (
                <div
                  key={index}
                  className="text-sm text-gray-600 dark:text-gray-400"
                >
                  {desc}
                </div>
              ))}
            </div>

            <div className="space-y-2">
              <h3 className="font-medium">Have a coupon?</h3>
              <div className="flex space-x-2">
                <input
                  className="flex-1 px-3 py-2 border rounded-md dark:bg-gray-700 dark:border-gray-600"
                  value={couponCode}
                  onChange={(e) => setCouponCode(e.target.value)}
                  placeholder="Enter coupon code"
                />
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    console.log("Apply coupon button clicked");
                    validateCoupon();
                  }}
                  className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                >
                  Apply
                </button>
              </div>
              {couponError && (
                <div className="text-red-500 text-sm">{couponError}</div>
              )}
              {discount > 0 && (
                <div className="text-green-600 dark:text-green-400">
                  Discount applied: -${discount.toFixed(2)}
                </div>
              )}
            </div>

            <div className="space-y-2 border-t pt-2">
              <div className="flex justify-between">
                <span>Subtotal</span>
                <span>${subtotal}</span>
              </div>
              {discount > 0 && (
                <div className="flex justify-between text-green-600">
                  <span>Discount</span>
                  <span>-${discount.toFixed(2)}</span>
                </div>
              )}
              <div className="flex justify-between">
                <span>Stripe Fee</span>
                <span>${stripeFee}</span>
              </div>
              <div className="flex justify-between">
                <span>Estimated Tax</span>
                <span>${tax}</span>
              </div>
              <div className="flex justify-between font-bold border-t pt-2">
                <span>Total</span>
                <span>${total}</span>
              </div>
            </div>
          </div>

          <div className="flex justify-between mt-6">
            <button
              onClick={() => setShowCheckoutSummary(false)}
              className="px-4 py-2 border rounded-md hover:bg-gray-100 dark:hover:bg-gray-700"
            >
              Cancel
            </button>
            <button
              onClick={handleProceedToStripe}
              className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
            >
              Complete Purchase
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`flex flex-col h-screen overflow-hidden bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-100 ${theme}`}
    >
      <div
        className={`flex flex-col h-screen bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-100 ${theme}`}
      >
        <button
          onClick={() => navigate("/mainPage")}
          className="h-14 bg-blue-100 dark:bg-gray-800 text-gray-900 dark:text-gray-100 dark:border-gray-700 flex items-center px-4 z-20"
        >
          <BsHouse size={20} />
        </button>

        <div className="fixed inset-0 bg-blue-100 bg-opacity-75 flex items-center justify-center p-4 dark:bg-gray-900 dark:bg-opacity-80">
          <div className="dark:bg-gray-800 rounded-lg shadow-xl overflow-hidden max-w-4xl w-full h-[500px] flex flex-col relative">
            <div className="flex justify-between px-5 pt-5 border-b dark:border-gray-700">
              <button
                className={`text-lg font-medium ml-40 ${
                  activeTab === "Basic"
                    ? "text-blue-600 border-b-2 border-blue-600 dark:text-blue-400 dark:border-blue-400"
                    : "text-gray-500 dark:text-gray-400"
                }`}
                onClick={() => setActiveTab("Basic")}
              >
                ResponXe Pro
              </button>
              <button
                className={`text-lg font-medium mr-40 ${
                  activeTab === "Professional"
                    ? "text-blue-600 border-b-2 border-blue-600 dark:text-blue-400 dark:border-blue-400"
                    : "text-gray-500 dark:text-gray-400"
                }`}
                onClick={() => setActiveTab("Professional")}
              >
                ResponXe Premium
              </button>
              {/* <button
                className={`text-lg font-medium mr-40 ${
                  activeTab === "Recharge"
                    ? "text-blue-600 border-b-2 border-blue-600 dark:text-blue-400 dark:border-blue-400"
                    : "text-gray-500 dark:text-gray-400"
                }`}
                onClick={() => setActiveTab("Recharge")}
              >
                Recharge
              </button> */}
            </div>

            {/* Notification Section */}
            {notification && (
              <div className="px-5 pt-4">
                <div
                  className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                  role="alert"
                >
                  <strong className="font-bold">Notice: </strong>
                  <span className="block sm:inline">{notification}</span>
                  <span
                    className="absolute top-0 bottom-0 right-0 px-4 py-3"
                    onClick={() => setNotification("")}
                  >
                    <svg
                      className="fill-current h-6 w-6 text-red-500"
                      role="button"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <title>Close</title>
                      <path d="M14.348 5.652a.5.5 0 00-.707 0L10 9.293 6.36 5.652a.5.5 0 10-.707.707L9.293 10l-3.64 3.64a.5.5 0 00.707.707L10 10.707l3.64 3.64a.5.5 0 00.707-.707L10.707 10l3.64-3.64a.5.5 0 000-.708z" />
                    </svg>
                  </span>
                </div>
              </div>
            )}

            <div className="flex items-center justify-center flex-1 p-5">
              {activeTab === "Basic" && (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                  {plans.Basic.map((plan) => (
                    <div
                      key={plan.name}
                      className="bg-white dark:bg-gray-700 p-5 rounded-lg shadow-md text-center border border-gray-200 dark:border-gray-600 w-64 flex flex-col h-full"
                    >
                      <div className="flex-grow">
                        <h3 className="text-xl font-bold text-gray-900 dark:text-gray-200 pt-5">
                          {plan.name}
                        </h3>
                        <p className="mt-2 text-2xl font-bold text-gray-900 dark:text-gray-200 pt-5">
                          ${plan.price}
                          <span className="text-base font-medium text-gray-500 dark:text-gray-400">
                            {"/mo"}
                          </span>
                        </p>
                        <div className="mt-2 text-left pt-5">
                          {Array.isArray(plan.description) ? (
                            plan.description.map((paragraph, index) => (
                              <p
                                key={index}
                                className="font-small text-gray-700 dark:text-gray-300 mb-2"
                              >
                                {paragraph}
                              </p>
                            ))
                          ) : (
                            <p className="font-small text-gray-700 dark:text-gray-300 mb-2">
                              {plan.description}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="mt-auto pt-5">
                        <button
                          className="w-full bg-blue-500 text-white rounded-md px-4 py-2 hover:bg-blue-700 dark:bg-blue-600 dark:hover:bg-blue-800"
                          onClick={() => makePayment(plan)}
                        >
                          Get Started
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {activeTab === "Professional" && (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                  {plans.Professional.map((plan) => (
                    <div
                      key={plan.name}
                      className="bg-white dark:bg-gray-700 p-5 rounded-lg shadow-md text-center border border-gray-200 dark:border-gray-600 w-64 flex flex-col h-full"
                    >
                      <div className="flex-grow">
                        <h3 className="text-xl font-bold text-gray-900 dark:text-gray-200 pt-5">
                          {plan.name}
                        </h3>
                        <p className="mt-2 text-2xl font-bold text-gray-900 dark:text-gray-200 pt-5">
                          ${plan.price}
                          <span className="text-base font-medium text-gray-500 dark:text-gray-400">
                            {"/mo"}
                          </span>
                        </p>
                        <div className="mt-2 text-left pt-5">
                          {Array.isArray(plan.description) ? (
                            plan.description.map((paragraph, index) => (
                              <p
                                key={index}
                                className="font-small text-gray-700 dark:text-gray-300 mb-2"
                              >
                                {paragraph}
                              </p>
                            ))
                          ) : (
                            <p className="font-small text-gray-700 dark:text-gray-300 mb-2">
                              {plan.description}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="mt-auto pt-5">
                        <button
                          className="w-full bg-blue-500 text-white rounded-md px-4 py-2 hover:bg-blue-700 dark:bg-blue-600 dark:hover:bg-blue-800"
                          onClick={() => makePayment(plan)}
                        >
                          Get Started
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {activeTab === "Recharge" && (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                  {plans.Recharge.map((plan) => (
                    <div
                      key={plan.name}
                      className="bg-white dark:bg-gray-700 p-5 rounded-lg shadow-md text-center border border-gray-200 dark:border-gray-600 w-64 flex flex-col h-full"
                    >
                      <div className="flex-grow">
                        <h3 className="text-xl font-bold text-gray-900 dark:text-gray-200 pt-5">
                          {plan.name}
                        </h3>
                        <p className="mt-2 text-2xl font-bold text-gray-900 dark:text-gray-200 pt-5">
                          ${plan.price}
                          <span className="text-base font-medium text-gray-500 dark:text-gray-400">
                            {"/one-time"}
                          </span>
                        </p>
                        <div className="mt-2 text-left pt-5">
                          {Array.isArray(plan.description) ? (
                            plan.description.map((paragraph, index) => (
                              <p
                                key={index}
                                className="font-small text-gray-700 dark:text-gray-300 mb-2"
                              >
                                {paragraph}
                              </p>
                            ))
                          ) : (
                            <p className="font-small text-gray-700 dark:text-gray-300 mb-2">
                              {plan.description}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="mt-auto pt-5">
                        <button
                          className="w-full bg-blue-500 text-white rounded-md px-4 py-2 hover:bg-blue-700 dark:bg-blue-600 dark:hover:bg-blue-800"
                          onClick={() => makePayment(plan)}
                        >
                          Recharge
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {showCheckoutSummary && <CheckoutSummary />}
    </div>
  );
}

export default MyPlan;
