import React, { useState } from "react";
import { BsTrash3 } from "react-icons/bs";
import { FiEdit2 } from "react-icons/fi";
import CodeBlockHandler from "./CodeBlockHandler";

const ChatHistoryDisplay = ({
  chatHistory,
  editIndex,
  editedQuestion,
  setEditedQuestion,
  submitHandlerRef,
  setEditIndex,
  handleQuestionDisplayed,
  getFileIcon,
  chatId,
  setChatHistory,
  setError,
  fetchWithAuth,
  serverUrl,
  setCurrentModel,
}) => {
  const [loadingImages, setLoadingImages] = useState({});

  const handleDeleteChat = async (index) => {
    try {
      const response = await fetchWithAuth(
        `${serverUrl}/api/delete-chat-entry`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            chatId,
            index,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete chat entry");
      }

      const data = await response.json();
      setChatHistory(data.chatHistory);
    } catch (error) {
      console.error("Error deleting chat entry:", error);
      setError("Failed to delete chat entry");
    }
  };

  const handleEditChat = (index, question, model) => {
    setEditIndex(index);
    setEditedQuestion(question);
    if (setCurrentModel) {
      setCurrentModel(model);
    }
  };

  const handleSaveEdit = async (e, editedQuestion, index) => {
    e.preventDefault();

    try {
      const newAnswer = await submitHandlerRef.current.handleSubmit(
        e,
        editedQuestion,
        index
      );

      console.log("newAnswer in handleSaveEdit: ", newAnswer);

      const response = await fetchWithAuth(`${serverUrl}/api/edit-chat-entry`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          chatId,
          index,
          newQuestion: editedQuestion,
          newAnswer: newAnswer,
          apiModel: chatHistory[index].apiModel,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to update chat entry");
      }

      const data = await response.json();
      setChatHistory(data.chatHistory);
      setEditIndex(null);
    } catch (error) {
      console.error("Error updating chat entry:", error);
      setError("Failed to update chat entry");
    }
  };

  const renderContent = (content, chatIndex) => {
    if (typeof content !== "string") return content;

    // Split content into text and image sections
    const parts = content.split(/!\[.*?\]\((.*?)\)/);

    return parts
      .map((part, index) => {
        if (index % 2 === 0) {
          // Text content
          if (!part.trim()) return null;
          return (
            <CodeBlockHandler
              key={`text-${chatIndex}-${index}`}
              answer={part}
            />
          );
        } else {
          // Image URL
          const imageKey = `${chatIndex}-${index}`;
          return (
            <div key={`image-${imageKey}`} className="my-4 flex justify-center">
              {loadingImages[imageKey] && (
                <div className="flex justify-center items-center h-16">
                  <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
                </div>
              )}
              <img
                src={part}
                alt="Generated illustration"
                className={`w-96 h-auto rounded-lg shadow-lg transition-opacity duration-200 ${
                  loadingImages[imageKey] ? "opacity-0" : "opacity-100"
                }`}
                style={{
                  maxWidth: "384px", // equivalent to w-96
                  objectFit: "contain",
                }}
                loading="lazy"
                onLoad={() => {
                  setLoadingImages((prev) => ({ ...prev, [imageKey]: false }));
                }}
                onError={(e) => {
                  console.error("Failed to load image:", part);
                  setLoadingImages((prev) => ({ ...prev, [imageKey]: false }));
                  e.target.style.display = "none";
                }}
              />
            </div>
          );
        }
      })
      .filter(Boolean); // Remove null entries
  };

  return (
    <div className="space-y-4">
      {chatHistory.map((chat, chatIndex) => (
        <div key={chatIndex} className="p-4 relative">
          <div className="flex items-center justify-between mb-0 bg-gray-100 dark:bg-gray-800 pr-3 pb-0 pl-3 pt-3 rounded-t-md">
            <span className="text-[16px] font-bold text-gray-800 dark:text-gray-200">
              Question:
            </span>
            <div className="flex items-center space-x-2">
              <BsTrash3
                className="cursor-pointer text-red-500 hover:text-red-700 dark:text-red-400 dark:hover:text-red-600"
                onClick={() => handleDeleteChat(chatIndex)}
              />
              <FiEdit2
                className="cursor-pointer text-blue-500 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-600"
                onClick={() =>
                  handleEditChat(
                    chatIndex,
                    chat.question || chat.content,
                    chat.apiModel
                  )
                }
              />
            </div>
          </div>

          {editIndex === chatIndex ? (
            <div>
              <pre
                className="w-full p-3 border border-gray-300 dark:border-gray-600 rounded-t-md font-sans text-[16px] leading-[1.5] text-gray-800 dark:text-gray-200 whitespace-pre-wrap"
                style={{ fontFamily: '"Inter", sans-serif' }}
              >
                <textarea
                  value={editedQuestion}
                  onChange={(e) => setEditedQuestion(e.target.value)}
                  className="w-full h-full bg-transparent resize-none outline-none text-gray-800 dark:text-gray-200"
                  style={{
                    fontFamily: "inherit",
                    fontSize: "inherit",
                    lineHeight: "inherit",
                  }}
                />
              </pre>
              <button
                onClick={(e) => handleSaveEdit(e, editedQuestion, chatIndex)}
                className="mt-2 px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded"
              >
                Save
              </button>
            </div>
          ) : (
            <pre
              onLoad={handleQuestionDisplayed}
              className="bg-gray-100 dark:bg-gray-800 rounded-b-md font-sans text-[16px] leading-[1.5] text-gray-800 dark:text-gray-200 whitespace-pre-wrap p-3"
              style={{ fontFamily: '"Inter", sans-serif' }}
            >
              {chat.question || chat.content}
            </pre>
          )}

          {chat.files && chat.files.length > 0 && (
            <div>
              <h4 className="font-bold mt-2 text-[18px] leading-[1.5] text-gray-800 dark:text-gray-200">
                Attached Files:
              </h4>
              <ul className="font-sans text-[16px] leading-[1.5] text-gray-800 dark:text-gray-200">
                {chat.files.map((file, fileIndex) => (
                  <li key={fileIndex}>
                    {getFileIcon(file.type)} {file.name}
                  </li>
                ))}
              </ul>
            </div>
          )}

          <h3 className="font-bold mt-5 text-[18px] leading-[1.5] text-gray-800 dark:text-gray-200">
            Answer:
          </h3>
          <div className="prose dark:prose-invert max-w-none">
            {renderContent(chat.answer, chatIndex)}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ChatHistoryDisplay;
