import React from "react";
import { Link } from "react-router-dom";

const Navigation = ({ handleModelChange, userPlanType }) => {
  console.log("userPlanType in APIColllection: ", userPlanType);

  const models = [
    { name: "GPT 4o mini", color: "emerald" },
    { name: "GPT 4o", color: "emerald" },
    // { name: "OpenAI o1 preview", color: "emerald" },
    // { name: "OpenAI o1 mini", color: "emerald" },
    { name: "Claude 3 haiku", color: "yellow" },
    { name: "Claude 3.5 sonnet", color: "yellow" },
    // { name: "Claude 3 opus", color: "yellow" },
    { name: "Gemini 1.5 Pro", color: "blue" },
    { name: "Gemini 2 Flash", color: "blue" },
    { name: "Machine Learning", color: "black" },
    { name: "Programming", color: "black" },
  ];

  // Define which models are accessible for free users
  const accessibleModels = ["GPT 4o mini", "Claude 3 haiku", "Gemini 2 Flash"];
  // const isFreeUser = userPlanType === "free";
  const isFreeUser = userPlanType?.toLowerCase() === "free";

  return (
    <nav className="navbar flex flex-col space-y-2 p-4 bg-white dark:bg-gray-800 rounded-lg shadow-md">
      {models.map((model) => {
        const isAccessible = accessibleModels.includes(model.name);
        const isRestricted = isFreeUser && !isAccessible;
        let textColorClass = "text-black hover:text-black";

        // If the model is restricted, apply gray color and disable hover
        const restrictedTextColorClass = "text-gray-400 cursor-not-allowed";

        return (
          <Link
            key={model.name}
            onClick={() => handleModelChange(model.name)}
            // className={`flex justify-left items-left ${
            //   isRestricted ? restrictedTextColorClass : textColorClass
            // } ${!isRestricted ? "cursor-pointer" : ""}`}
            className={`rounded text-left w-full transition-colors duration-200 ${
              isRestricted
                ? "text-gray-400 cursor-not-allowed"
                : "text-gray-800 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-800"
            }`}
            disabled={isRestricted} // Disable the button if restricted
            title={isRestricted ? "Upgrade your plan to access this model" : ""}
          >
            {/* {model.name} {isRestricted && <span className="ml-2">🔒</span>} */}
            <span className="flex items-center p-0.5 rounded-lg transition-all duration-200">
              <span>{model.name}</span>
              {isRestricted && <span className="ml-1">🔒</span>}
            </span>
          </Link>
        );
      })}
    </nav>
  );
};

export default Navigation;
