import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ChatCategories from "./ChatCategories";
import logo from "../Images/logo.png";

const Sidebar = ({
  error,
  savedChats,
  chatNames,
  loadChat,
  fetchSavedChats,
  serverUrl,
  sidebarOpen,
}) => {
  const navigate = useNavigate();

  // Move handler functions into Sidebar
  const handleResponxe = async () => {
    try {
      navigate("/mainpage");
    } catch (error) {
      console.error("mainpage failed:", error.response || error);
      alert("Failed to go to mainpage.");
    }
  };

  return (
    <div
      className={`fixed top-0 left-0 h-full bg-gray-100 dark:bg-gray-800 border-r border-gray-200 dark:border-gray-700 transition-all duration-300 ease-in-out z-30 ${
        sidebarOpen ? "w-[250px]" : "w-0"
      } overflow-hidden`}
    >
      <aside className="flex flex-col h-full">
        <div className="flex items-center mb-4 mt-4 ml-4">
          <div className="w-10 h-10 rounded-full overflow-hidden">
            <img
              src={logo}
              alt="Logo"
              className="w-9 h-9 rounded-full overflow-hidden border border-purple-200 dark:border-purple-700 text-purple-600"
            />
          </div>
          <button
            onClick={handleResponxe}
            // className="px-2 py-2 rounded text-left w-full text-gray-800 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-200"
            className="px-2 py-2 rounded text-left w-full text-purple-500 dark:text-purple-200 hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-200 text-lg font-roboto"
          >
            Respon<span className="font-bold">X</span>e
          </button>
        </div>

        <div className="flex-grow overflow-y-auto p-4">
          {error && <div className="text-red-500 mb-4">{error}</div>}
          {savedChats.length === 0 ? (
            <p className="text-gray-500 italic"></p>
          ) : (
            <ChatCategories
              savedChats={savedChats}
              chatNames={chatNames}
              loadChat={loadChat}
              fetchSavedChats={fetchSavedChats}
              serverUrl={serverUrl}
            />
          )}
        </div>
      </aside>
    </div>
  );
};

export default Sidebar;
