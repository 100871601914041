import React, { useMemo, useState } from "react";
import { BsTrash3 } from "react-icons/bs";
import axios from "axios";

const ChatCategories = ({
  savedChats,
  chatNames,
  loadChat,
  fetchSavedChats,
  serverUrl,
}) => {
  const [categoryOpenState, setCategoryOpenState] = useState({
    today: true,
    yesterday: false,
    lastWeek: false,
    lastMonth: false,
    last90Days: false,
  });

  const categorizeChats = useMemo(() => {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    const lastWeekStart = new Date(today);
    lastWeekStart.setDate(lastWeekStart.getDate() - 7);
    const lastMonthStart = new Date(today);
    lastMonthStart.setDate(lastMonthStart.getDate() - 30);
    const last90DaysStart = new Date(today);
    last90DaysStart.setDate(last90DaysStart.getDate() - 90);

    return savedChats.reduce(
      (acc, chat) => {
        const chatDate = new Date(chat.createdAt);
        if (chatDate >= today) {
          acc.today.push(chat);
        } else if (chatDate >= yesterday) {
          acc.yesterday.push(chat);
        } else if (chatDate >= lastWeekStart) {
          acc.lastWeek.push(chat);
        } else if (chatDate >= lastMonthStart) {
          acc.lastMonth.push(chat);
        } else if (chatDate >= last90DaysStart) {
          acc.last90Days.push(chat);
        } else {
          acc.older.push(chat);
        }
        return acc;
      },
      {
        today: [],
        yesterday: [],
        lastWeek: [],
        lastMonth: [],
        last90Days: [],
        older: [],
      }
    );
  }, [savedChats]);

  const toggleCategory = (category) => {
    setCategoryOpenState((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
  };

  // const truncateChatName = (name, maxLength = 25) => {
  //   if (name.length <= maxLength) return name;
  //   return `${name.substring(0, maxLength)}...`;
  // };
  const truncateChatName = (name, maxLength = 26) => {
    if (name.length <= maxLength) return name;
    return name.substring(0, maxLength);
  };

  const handleDeleteChat = async (chatId) => {
    console.log("I am in handle dlete and here is my chat Id: ", chatId);
    try {
      const response = await axios.delete(
        `${serverUrl}/api/delete-chat/${chatId}`,
        // `/api/delete-chat/${chatId}`,
        {
          withCredentials: true,
          timeout: 10000,
        }
      );
      if (response.status === 200) {
        fetchSavedChats(); // Refresh chat list after deletion
      } else {
        console.error("Failed to delete chat");
      }
    } catch (error) {
      console.error("Error deleting chat:", error);
    }
  };

  const renderChatList = (chats, category) => {
    if (chats.length === 0) {
      return (
        <p className="text-gray-500 dark:text-gray-400 italic">
          No chats available
        </p>
      );
    }
    return (
      <ul
        className={`space-y-2 ${
          categoryOpenState[category] ? "block" : "hidden"
        }`}
      >
        {chats.map((chat) => (
          <li
            key={chat.chatId}
            className="group cursor-pointer px-1 py-2 rounded text-left w-full text-gray-700 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors duration-150 flex justify-between items-center"
          >
            <div className="relative overflow-hidden flex-grow mr-2">
              <span
                style={{
                  fontFamily: "'Arial', sans-serif",
                  fontSize: "0.855rem",
                }}
                onClick={() => loadChat(chat.chatId)}
                title={
                  chatNames[chat.chatId] || chat.chatName || `Chat ${chat.id}`
                }
                className="block w-full h-full"
              >
                {truncateChatName(
                  chatNames[chat.chatId] || chat.chatName || `Chat ${chat.id}`
                )}
              </span>
              {(chatNames[chat.chatId] || chat.chatName || `Chat ${chat.id}`)
                .length > 25 && (
                <span className="absolute right-0 top-0 h-full w-8 bg-gradient-to-l from-gray-100 group-hover:from-gray-200 dark:from-gray-900 dark:group-hover:from-gray-700 to-transparent pointer-events-none transition-all duration-200 delay-75"></span>
              )}
            </div>
            <BsTrash3
              onClick={() => handleDeleteChat(chat.chatId)}
              className="text-gray-500 dark:text-gray-400 hover:text-gray-800 dark:hover:text-white ml-2 cursor-pointer flex-shrink-0"
            />
          </li>
        ))}
      </ul>
    );
  };

  const renderCategory = (category, label) => {
    const chats = categorizeChats[category];
    if (chats.length === 0) return null;
    return (
      <div>
        <button
          onClick={() => toggleCategory(category)}
          className="flex items-center justify-between w-full p-2 hover:bg-gray-200 dark:hover:bg-gray-700 rounded text-purple-400 dark:text-purple-300 font-semibold text-m font-sans"
        >
          <span>{label}</span>
        </button>
        {renderChatList(chats, category)}
      </div>
    );
  };

  return (
    <div>
      {renderCategory("today", "Today")}
      {renderCategory("yesterday", "Yesterday")}
      {renderCategory("lastWeek", "Previous 7 days")}
      {renderCategory("lastMonth", "Previous 30 days")}
      {renderCategory("last90Days", "Previous 90 days")}
      {renderCategory("older", "Older than 90 days")}
    </div>
  );
};

export default ChatCategories;
