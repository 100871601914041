import React from "react";
import { countries } from "./countriesData";

// Function to get country flag emoji from ISO code
const getFlagEmoji = (countryCode) => {
  const codePoints = countryCode
    .toUpperCase()
    .split("")
    .map((char) => 127397 + char.charCodeAt());
  return String.fromCodePoint(...codePoints);
};

const CountrySelect = ({ selectedCountry, onSelect, error }) => {
  return (
    <div>
      <select
        className={`border-2 ${
          error ? "border-red-500" : "border-gray-300"
        } p-3 w-full rounded-md focus:border-purple-500`}
        value={selectedCountry?.name || ""}
        onChange={(e) => {
          const country = countries.find((c) => c.name === e.target.value);
          onSelect(country);
        }}
      >
        <option value="">Select Country</option>
        {countries.map((country) => (
          <option key={country.iso} value={country.name}>
            {getFlagEmoji(country.iso)} {country.name}
          </option>
        ))}
      </select>
      {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
    </div>
  );
};

export default CountrySelect;
